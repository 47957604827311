import debounce from 'lodash.debounce'

export default class Documentation {
  constructor() {
    this.docs = document.querySelector('.documentation')
    if (!this.docs) return

    $('.search-menu__controls__clear').hide()

    this.allCheckboxes = Array.from(
      this.docs.querySelectorAll('.item-input__checkbox')
    )
    this.searchInput = this.docs.querySelector(
      '.search-menu__controls__input-container__input'
    )
    this.mobileMenuButton = this.docs.querySelector(
      '.documentation__mobile-search'
    )
    this.mobileSearchMenu = this.docs.querySelector(
      '.documentation__search-menu'
    )
    this.clearFiltersButton = this.docs.querySelector(
      '.search-menu__controls__clear'
    )
    this.expandButtons = Array.from(
      this.docs.querySelectorAll('.expand__heading__icon')
    )
    this.itemInputs = Array.from(this.docs.querySelectorAll('.item-input'))
    this.loadMore = document.querySelector('.documentation__load-more button')

    this.activeFilters = []
    this.page = 1
    this.query = ''

    this.initializeListeners()
  }

  toggleMobileVisibility = () => {
    this.mobileSearchMenu.classList.toggle('search-menu--visible')
  }

  resetForm = () => {
    this.allCheckboxes.forEach(checkbox => (checkbox.checked = false))
    this.searchInput.value = ''
    this.activeFilters = []
    this.query = ''
    this.page = 1

    this.toggleClearFilter()

    this.filterDocs()
  }

  handleCheckboxClick = event => {
    const filterName = event.target.name

    if (!this.activeFilters.includes(filterName))
      this.activeFilters.push(filterName)
    else
      this.activeFilters = this.activeFilters.filter(
        filter => filter !== filterName
      )

    this.page = 1
    this.filterDocs()
  }

  filterDocs = () => {
    $.ajax(
      `/documentation.js?filters=${JSON.stringify(this.activeFilters)}&page=${
        this.page
      }&query=${this.query}`
    )
  }

  handleExpandClick = event => {
    const expandItems = event.target.parentNode.nextSibling
    expandItems.classList.toggle('expand__items--hidden')
    event.target.classList.toggle('expand__heading__icon--rotate')
  }

  toggleClearFilter = event => {
    if (
      $('input:checked').length ||
      $('.search-menu__controls__input-container__input').val()
    ) {
      $('.search-menu__controls__clear').show()
    } else {
      $('.search-menu__controls__clear').hide()
    }
  }

  loadNextPage = () => {
    this.page += 1
    this.filterDocs()
  }

  initializeListeners = () => {
    this.mobileMenuButton.addEventListener('click', this.toggleMobileVisibility)
    this.clearFiltersButton.addEventListener('click', this.resetForm)
    this.allCheckboxes.forEach(checkbox =>
      checkbox.addEventListener('click', this.handleCheckboxClick)
    )
    this.searchInput.addEventListener(
      'input',
      debounce(e => {
        this.page = 1
        this.query = e.target.value
        this.filterDocs()
      }, 250)
    )
    this.expandButtons.forEach(button =>
      button.addEventListener('click', this.handleExpandClick)
    )
    this.loadMore.addEventListener('click', this.loadNextPage)

    $('.search-menu__controls__input-container__input').on(
      'keyup',
      this.toggleClearFilter
    )

    this.itemInputs.forEach(item => {
      item.addEventListener('click', this.toggleClearFilter)
    })
  }
}
