import Vimeo from '@vimeo/player'

export default class Video {
  constructor() {
    if ($('.video').length > 0) {
      let tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      let firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = () => {
        $('.video').each((index, video) => {
          const $video = $(video)

          if ($video.find('video').length > 0) {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.playVideo($video)
            })
          } else if (
            $video
              .find('iframe')
              .attr('src')
              .includes('youtube')
          ) {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.hideCover($video)

              new YT.Player($video.find('iframe').attr('id'), {
                events: {
                  onReady: onPlayerReady,
                },
              })
            })
          } else {
            $video.find('.video__play, .video__cover').on('click', () => {
              this.hideCover($video)

              const vimeoVideo = new Vimeo($video.find('iframe').attr('id'))
              vimeoVideo.play()
            })
          }
        })
      }

      window.onPlayerReady = event => {
        event.target.playVideo()
      }

      // Find all videos
      var allVideos = $(
        "iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], video"
      )

      // The element that is fluid width
      var fluidEl = $('.video__file')

      // Figure out and save aspect ratio for each video
      allVideos.each(function() {
        if ($(this).is('iframe')) {
          $(this)
            .data('aspectRatio', $(this).outerHeight() / this.clientWidth)

            // and remove the hard coded width/height
            .removeAttr('height')
            .removeAttr('width')
        } else {
          $(this)
            .data('aspectRatio', this.height / this.width)

            // and remove the hard coded width/height
            .removeAttr('height')
            .removeAttr('width')
        }
      })

      // // When the window is resized
      // $(window).resize(function() {
      //   var newWidth = fluidEl.width();
      //   // Resize all videos according to their own aspect ratio
      //   allVideos.each(function() {

      //     var el = $(this);
      //     el
      //       .width(newWidth)
      //       .height(newWidth * el.data('aspectRatio'));
      //   });
      // // Kick off one resize to fix all videos on page load
      // }).resize();
    }
  }

  hideCover(video) {
    const cover = video.find('.video__cover'),
      play = video.find('.video__play')

    play.fadeOut(400)
    cover.fadeOut(400)
  }

  playVideo(video) {
    this.hideCover(video)
    const file = video.find('video')

    file[0].play()
  }
}
