import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()

import Documentation from './documentation'

new Documentation()

import Formbuilder from './formbuilder'
import Events from './events'
import Video from './video'

new Formbuilder()
new Events()
new Video()

import { initMediaHandlers } from './media'
import { initMenuHandlers } from './menu'

initMediaHandlers()
initMenuHandlers()
