import moment from 'moment/src/moment';
import 'fullcalendar';

export default class Events {
  constructor() {
    this.calendar = $('#calendar');
    this.singleDay = $('#single-day');
    this.listView = $('#list-view');

    this.initCalendar();

    this.viewToggle = $('.event-views__button');

    this.viewToggle.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.viewChange(target);
    });
  }

  initCalendar() {
    this.calendar.fullCalendar({
      events: '/events.json',
      header: {
        left: '',
        center: 'prev,title,next',
        right: ''
      },
      views: {
        list: {
          duration: {
            months: 1
          }
        }
      },
      dayNamesShort: ['S', 'M', 'T', 'W', 'TH', 'F', 'S'],
      height: 'auto',
      fixedWeekCount: false,
      showNonCurrentDates: false,
      viewRender: (view) => {
        let formattedTitle = $('.fc-center h2').text().split(' ');
        formattedTitle = `${formattedTitle[0]} <span>${formattedTitle[1]}</span>`;
        $('.fc-center h2').html(formattedTitle);
      },
      eventAfterRender: (e, element) => {
        if (element.parent().parent().index() === 0) {
          const index = element.parent().index();
          element.closest('.fc-week').find('.fc-content-skeleton thead td').eq(index).addClass('fc-has-event');
        }

        $('.fc-icon-left-single-arrow').attr('aria-label', 'previous month');
        $('.fc-prev-button').attr('aria-label', 'previous month');
        $('.fc-icon-right-single-arrow').attr('aria-label', 'next month');
        $('.fc-next-button').attr('aria-label', 'next month');
      },
      eventAfterAllRender: (view) => {
        const sources = this.calendar.fullCalendar('getEventSources');

        $('.fc-state-highlight').children('.fc-day-number').addClass('active');

        if (!this.selectedDate) {
          $('.fc-today').find('.fc-day-number').addClass('active');
        } else {
          const dateString = moment(this.selectedDate).format('YYYY-MM-DD');
          $('.fc-day-top[data-date="' + dateString + '"]').find('.fc-day-number').addClass('active');
        }

        if (!this.selectedDate) {
          this.selectedDate = this.calendar.fullCalendar('getDate');
          this.singleDay.next().fadeIn(100);
        }

        $('.fc-day-number').on('click', (e) => {
          this.calendar.find('.fc-day-number').removeClass('active');
          $(e.currentTarget || e.target).addClass('active');
        });

        if (sources.length > 0) {
          if (view.type === 'month') {
            $.ajax({
              url: `/events/day_events.js`,
              data: {
                day: this.selectedDate.format()
              },
              success: () => {
                this.singleDay.next().fadeOut(300);
              }
            });
          } else {
            this.listView.next().fadeIn(100);

            $.ajax({
              url: `/events.js`,
              data: {
                begin_date: view.intervalStart.format(),
                end_date: view.intervalEnd.format()
              },
              success: () => {
                this.listView.next().fadeOut(300);
              }
            });
          }
        }

        this.calendar.next().fadeOut(300);
      },
      dayClick: (date) => {
        this.selectedDate = date;
        this.singleDay.next().fadeIn(100);

        $.ajax({
          url: `/events/day_events.js?day=${date.format()}`,
          success: () => {
            this.singleDay.next().fadeOut(300);
          }
        });
      }
    });

    $('.fc-prev-button, .fc-next-button').on('click', (e) => {
      if ($('.events-calendar').hasClass('list-view')) {
        this.listView.next().fadeIn(100);
      } else {
        this.calendar.next().fadeIn(100);
      }
    });
  }

  viewChange(target) {
    if (!target.hasClass('active')) {
      this.viewToggle.removeClass('active');
      target.addClass('active');

      const targetView = target.data('view');

      $('.events-calendar').removeClass('event-results');

      if (targetView === 'list') {
        $('.events-calendar').addClass('list-view');
      } else {
        $('.events-calendar').removeClass('list-view');
      }

      this.showLoadingSpinners();

      this.selectedDate = this.calendar.fullCalendar('getDate');
      this.calendar.fullCalendar('changeView', targetView);
    }
  }

  showLoadingSpinners() {
    if ($('.events-calendar').hasClass('list-view')) {
      this.listView.next().fadeIn(100);
    } else {
      this.singleDay.next().fadeIn(100);
    }
  }
}
