import Vimeo from '@vimeo/player'
import Siema from 'siema'

// Loads the YouTube Iframe API with an optional callback.
const _loadYouTubeIframeAPI = callback => {
  if (window.hasOwnProperty('onYouTubeIframeAPIReady')) return

  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  if (callback) window.onYouTubeIframeAPIReady = callback
}

// attempts to play the video.
const _playVideo = ({ coverEl, player, videoType }) => {
  $(coverEl).fadeOut(300)

  switch (videoType) {
    case 'youtube':
      player.playVideo()
      break

    case 'vimeo':
      player.play()
      break

    default:
      player.play()
      break
  }
}

// Finds all video media elements on the page and connects event handlers.
export const initVideoHandlers = () => {
  const videoMediaEls = [...document.querySelectorAll('.media--video')]

  // Bail early if no media elements.
  if (videoMediaEls.length < 1) return

  // Load YouTube iframe API
  _loadYouTubeIframeAPI(() => {
    videoMediaEls.forEach(mediaEl => {
      const videoType = mediaEl.dataset.videoType
      const videoId = mediaEl.dataset.videoId

      const videoEl = mediaEl.querySelector('video')
      const iframeEl = mediaEl.querySelector('iframe')
      const coverEl = mediaEl.querySelector('.media--video__cover')

      let player
      switch (videoType) {
        case 'youtube':
          player = new YT.Player(iframeEl, { videoId })
          break

        case 'vimeo':
          player = new Vimeo(iframeEl, { id: videoId })
          break

        default:
          player = videoEl
          break
      }

      const context = { coverEl, player, videoType }

      const playButtonEl = mediaEl.querySelector('.media--video__cover__play')
      if (playButtonEl)
        playButtonEl.addEventListener('click', () => _playVideo(context))
    })
  })
}

export const initGalleryHandlers = () => {
  const galleryMediaEls = [...document.querySelectorAll('.media--gallery')]

  // Bail early if no media elements.
  if (galleryMediaEls.length < 1) return

  galleryMediaEls.forEach(el => {
    const controller = new Siema({
      selector: '.media--gallery__slides',
      loop: true,
    })

    const prevButtonEl = el.querySelector('.media--gallery__navigation--prev')
    if (prevButtonEl)
      prevButtonEl.addEventListener('click', () => controller.prev())

    const nextButtonEl = el.querySelector('.media--gallery__navigation--next')
    if (nextButtonEl)
      nextButtonEl.addEventListener('click', () => controller.next())
  })
}

// Finds all media elements on the page and connects event handlers.
export const initMediaHandlers = () => {
  initVideoHandlers()
  initGalleryHandlers()
}
